import React from 'react';
import SalesReport from '../../common/SalesReport'


export default function VendorReports(props) { 

  return(
    <SalesReport 
      vendorID = {'*'}
    />
  )

}
