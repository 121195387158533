import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core'
// form & Validation
import { Formik, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import * as Validation from '../../Validation'
// core components
import { Grid, TextField } from '@material-ui/core'
import InputMask from 'react-input-mask'
import Typography from '@mui/material/Typography';
// common components
import { RegularTextFieldSmall } from '../../../components/common/CustomTextFields/TextFieldSmall'
import Button from '../../../components/common/CustomButtons/Button';
import { Text } from '../../../components/common/LanguageProvider';
import { QuoteBanner2 } from '../../../components/common/QuoteBanner2'
import SubmitResult from './SubmitResult';

// icons
import InputAdornment from '@mui/material/InputAdornment'
import CreditCardIcon from '@mui/icons-material/CreditCard'
import { FaCcVisa, FaCcMastercard, FaCcAmex } from 'react-icons/fa'

//style
import formStyle from '../../../assets/jss/styles/formStyle'

const useStyles = makeStyles(formStyle)

// const queryString = new URLSearchParams(window.location.search).get('value')
const VALIDATION_SCHEMA = Yup.object().shape({
    cardHolderName : Validation.validRequiredField(),
    creditCardNumber : Validation.validCreditCardNumber(),
    cardExpired : Validation.validCardEexpirationDate(),
    cardcvv : Yup.string().when('creditCardType', (creditCardType) => {
      return Yup.string().required("FieldIsRequired")
          .min(3, 'Enter correct digits number')
          .test('Card CVV', function(value) {
              if (!value) {
              return this.createError({ message: `FieldIsRequired` })
              }
              else{
              // MasterCard Visa - 3 digit number code
              // AMEX  - 4 digit number code
              const regEx = /^\d{3}$/;
              const amxRegEx = /^\d{4}$/;
              if (creditCardType === 'AmericanExpress' && amxRegEx.test(value.replace(/\s/g, ''))) {
                  return true;
              }else if ((creditCardType === 'Visa' || creditCardType === 'MasterCard') && regEx.test(value.replace(/\s/g, ''))){
                  return true;
              }
              else {
                  return this.createError({ message: `InvalidSecretCode` })
              }
              }
          })
  })
})

// ValidMessage
function validMessage(fieldName) {
  return (
      <ErrorMessage
      name={fieldName}
      render={(msg) => (
          <div style={{ color: 'red', marginLeft: '1vh', fontSize: 12 }}>
            <Text tid={`Validation.${msg}`}></Text>
          </div>
      )}
      />
  )
}
    // get credit card icon
    function getCrditCardIcon(cardNumber) {
      switch(cardNumber) {
      case '4':
      return <FaCcVisa style={{ marginLeft:'1vh' }} />
      case '5':
      return <FaCcMastercard style={{ marginLeft:'1vh' }} />
      case '3':
      return <FaCcAmex style={{ marginLeft:'1vh' }} />
      default:
      return <CreditCardIcon style={{ marginLeft:'1vh' }} />
      }
  }


const Payment = (props) => {
  // const { } = props;
  const classes = useStyles()

  const queryString = new URLSearchParams(props.location.search);

  // URL에서 'application' 쿼리 파라미터의 값을 가져와 JSON 객체로 파싱합니다.
  const applicationData = queryString.get('application');
  const applicationJson = applicationData ? JSON.parse(decodeURIComponent(applicationData)) : {};

  // console.log(applicationJson)

  // inintal values
  const INITIAL_VALUES = {
    confirmationNo: queryString.get('confirmationNo'),
    contactName : queryString.get('contactName').replace(/[\W_]/g, ' '),
    paymentAmount : queryString.get('paymentAmount'),
    creditCardType : '',
    cardHolderName : '',
    creditCardNumber : '',
    cardExpired: '',
    cardcvv : '',
    // paymentMethod: applicationJson.payment.paymentMethod,
    insuredpersons: applicationJson.insuredpersons
}

  
const [submitted, setSubmitted] = useState(false);
const [formData, setFormData] = useState([]);

// handleSubmit
const handleSubmit = async (values) => {
    setFormData(values)
    setSubmitted(true)
}

if (submitted === false) {
  return (
    <>
      <QuoteBanner2 title={'Payment'} subTitle={''} links={[]}/>

      <Grid container justifyContent="center">
        <Grid item container xs={12} sm={12} md={11} lg={8} xl={6}>
          {/* <main className={classes.form} style={{ marginBottom:'8vh', padding:isMobile?'0':'0 2vh', border: isMobile ? 'none': '1px solid #efefef', boxShadow: isMobile?'none':'5px 5px 20px #efefef' }}> */}
          <main className={classes.form} style={{ marginBottom:'4vh', padding:'0 5vh', border: '1px solid #efefef', boxShadow: '5px 5px 20px #efefef' }}>
            <Formik
                initialValues={INITIAL_VALUES}
                validationSchema={VALIDATION_SCHEMA}
                onSubmit={(values) => {
                    // console.log(values)
                    handleSubmit(values)
                }} 
            >
                {({ values, handleChange, handleBlur, setFieldValue, errors }) => (
                <Form>
                    {/* {console.log(errors)} */}
                    <Grid item container className={classes.formWrapper2}>

                    <Grid item container spacing={2} xs={12} sm={12} md={12} lg={8} xl={7}>

                                {/* Show if Over 60 Canadian Vendor Application */}
                                {applicationJson.insuredpersons && applicationJson.insuredpersons.some(person => person.travelQuote60Id && person.travelQuote60Id.trim() !== '') && (
                                  <>
                                  <Grid item xs={12} sm={12} md={12} style={{ margin: '2vh 0' }}>
                                    <span className={classes.spanTitle}>
                                      <Text tid={'Quote.SelectCoverage'}/>
                                    </span>
                                  </Grid>

                                  {applicationJson.insuredpersons
                                    .filter(person => person.travelQuote60Id && person.travelQuote60Id.trim() !== '') // travelQuote60Id가 '' 또는 null이 아닌 요소만 필터링
                                    .map((person, pIndex) => (
                                    <React.Fragment key={person.travelQuote60Id}>
                                        <Grid item container>
                                          <Typography>{person.firstName} {person.lastName} ({person.birthdate}, {person.age} yrs)</Typography>
                                        </Grid>

                                        <Grid item container spacing={1} key={pIndex}>
                                            {person.allianz !== 0 &&
                                                <Grid item xs={12} md={4}>
                                                    <Button
                                                        color={values.insuredpersons[pIndex].selectedProduct === 'allianz' ? 'primary' : 'secondary'}
                                                        className={classes.button}
                                                        style={{ padding: '1vh', width: '100%', margin: '0', height: '10vh' }}
                                                        onClick={() => 
                                                          {
                                                            const planId = person.destCountryCode === 'US' ? 'PLCSMED002' : 'PLCSMED004';
                                                            const planName = person.destCountryCode === 'US' ? 'Canadian Single Trip (60-89) - Worldwide' : 'Canadian Single Trip (60-89) - Non USA';

                                                            // Update the individual fields using setFieldValue
                                                            setFieldValue(`insuredpersons[${pIndex}].selectedProduct`, 'allianz');
                                                            setFieldValue(`insuredpersons[${pIndex}].compnayName`, 'Allianz');
                                                            setFieldValue(`insuredpersons[${pIndex}].coverage`, '10000000');
                                                            setFieldValue(`insuredpersons[${pIndex}].insuranceAmount`, person.allianz);
                                                            setFieldValue(`insuredpersons[${pIndex}].totalAmount`, person.allianz);
                                                            setFieldValue(`insuredpersons[${pIndex}].plan_price`, person.allianz);
                                                            setFieldValue(`insuredpersons[${pIndex}].planId`, planId);
                                                            setFieldValue(`insuredpersons[${pIndex}].planName`, planName);

                                                            // Recalculate the total payment amount
                                                            const newTotal = values.insuredpersons.reduce((total, item, index) => {
                                                              if (index !== pIndex) {
                                                                return total + parseFloat(item.totalAmount || 0);
                                                              }
                                                              return total + person.allianz;  // Add the updated amount for the current person
                                                            }, 0);

                                                            // Update the total payment amount
                                                            setFieldValue('paymentAmount', newTotal);

                                                          }
                                                        }
                                                        fullWidth
                                                    >
                                                        <div>
                                                        Allianz
                                                        <Typography style={{ fontSize: '22px', fontWeight:'700', color: values.insuredpersons[pIndex].selectedProduct === 'allianz' ? '#eee' : '#3f51b5' }}>
                                                            $ {person.allianz}
                                                        </Typography>
                                                        </div>
                                                    </Button>
                                                </Grid>
                                            }

                                            {person.bluecross !== 0 &&
                                                <Grid item xs={12} md={4}>
                                                    <Button
                                                        color={values.insuredpersons[pIndex].selectedProduct === 'bluecross' ? 'primary' : 'secondary'}
                                                        className={classes.button}
                                                        style={{ padding: '1vh', width: '100%', margin: '0', height: '10vh' }}
                                                        onClick={() => 
                                                          {
                                                            const planId = 'PLCSMED007';
                                                            const planName = 'Emergency Medical Care';

                                                            // Update the individual fields using setFieldValue
                                                            setFieldValue(`insuredpersons[${pIndex}].selectedProduct`, 'bluecross');
                                                            setFieldValue(`insuredpersons[${pIndex}].compnayName`, 'BlueCross');
                                                            setFieldValue(`insuredpersons[${pIndex}].coverage`, '5000000');
                                                            setFieldValue(`insuredpersons[${pIndex}].insuranceAmount`, person.bluecross);
                                                            setFieldValue(`insuredpersons[${pIndex}].totalAmount`, person.bluecross);
                                                            setFieldValue(`insuredpersons[${pIndex}].plan_price`, person.bluecross);
                                                            setFieldValue(`insuredpersons[${pIndex}].planId`, planId);
                                                            setFieldValue(`insuredpersons[${pIndex}].planName`, planName);

                                                            // Recalculate the total payment amount
                                                            const newTotal = values.insuredpersons.reduce((total, item, index) => {
                                                              if (index !== pIndex) {
                                                                return total + parseFloat(item.totalAmount || 0);
                                                              }
                                                              return total + person.bluecross;  // Add the updated amount for the current person
                                                            }, 0);

                                                            // Update the total payment amount
                                                            setFieldValue('paymentAmount', newTotal);

                                                          }
                                                        }
                                                        fullWidth
                                                    >
                                                        <div>
                                                        Blue Cross
                                                        <Typography style={{ fontSize: '22px', fontWeight:'700', color: values.insuredpersons[pIndex].selectedProduct === 'bluecross' ? '#eee' : '#3f51b5' }}>
                                                            $ {person.bluecross}
                                                        </Typography>
                                                        </div>
                                                    </Button>
                                                </Grid>
                                            }

                                            {person.tugo !== 0 &&
                                                <Grid item xs={12} md={4}>
                                                    <Button
                                                        color={values.insuredpersons[pIndex].selectedProduct === 'tugo' ? 'primary' : 'secondary'}
                                                        className={classes.button}
                                                        style={{ padding: '1vh', width: '100%', margin: '0', height: '10vh' }}
                                                        onClick={() => 
                                                          {
                                                            const planId = person.destCountryCode === 'US' ? 'PLCSMED005' : 'PLCSMED006';
                                                            const planName = person.destCountryCode === 'US' ? 'Traveller - Medical Worldwide - Single Trip (SW)' : 'Traveller - Medical Worldwide xUSA - Single Trip (SX)';

                                                            // Update the individual fields using setFieldValue
                                                            setFieldValue(`insuredpersons[${pIndex}].selectedProduct`, 'tugo');
                                                            setFieldValue(`insuredpersons[${pIndex}].compnayName`, 'Tugo');
                                                            setFieldValue(`insuredpersons[${pIndex}].coverage`, '5000000');
                                                            setFieldValue(`insuredpersons[${pIndex}].insuranceAmount`, person.tugo);
                                                            setFieldValue(`insuredpersons[${pIndex}].totalAmount`, person.tugo);
                                                            setFieldValue(`insuredpersons[${pIndex}].plan_price`, person.tugo);
                                                            setFieldValue(`insuredpersons[${pIndex}].planId`, planId);
                                                            setFieldValue(`insuredpersons[${pIndex}].planName`, planName);

                                                            // Recalculate the total payment amount
                                                            const newTotal = values.insuredpersons.reduce((total, item, index) => {
                                                              if (index !== pIndex) {
                                                                return total + parseFloat(item.totalAmount || 0);
                                                              }
                                                              return total + person.tugo;  // Add the updated amount for the current person
                                                            }, 0);

                                                            // Update the total payment amount
                                                            setFieldValue('paymentAmount', newTotal);

                                                            // console.log(values)

                                                          }
                                                        }
                                                        fullWidth
                                                    >
                                                        <div>
                                                        TuGo
                                                        <Typography style={{ fontSize: '22px', fontWeight:'700', color: values.insuredpersons[pIndex].selectedProduct === 'tugo' ? '#eee' : '#3f51b5' }}>
                                                            $ {person.tugo}
                                                        </Typography>
                                                        </div>
                                                    </Button>
                                                </Grid>
                                            }
                                        </Grid>
                                    </React.Fragment>
                                  ))}
                                  </>
                                )}

                                
                                
                    </Grid>
                        
                      <Grid item container spacing={2}>
                          {/* applicant info */}
                          <Grid item container spacing={1} style={{padding:'2vh 0'}}>
                              <Grid  item xs={12} sm={12} md={12} style={{ margin: '2vh 0' }}>  
                                  <span className={classes.spanTitle}>
                                      <Text tid={'Quote.Step.Application'}/>
                                  </span>
                              </Grid>
                              
                              <Grid item xs={12} sm={4} md={4}>   
                                  <RegularTextFieldSmall
                                      name={`confirmationNo`}
                                      readOnly={true}
                                      type="text"
                                      value={values.confirmationNo}
                                      // label= {'Quote.ConfirmationNo'}
                                      label= {'Confirmation No.'}
                                  />
                              </Grid>

                              <Grid item xs={12} sm={4} md={4}>   
                                  <RegularTextFieldSmall
                                      name={`contactName`}
                                      readOnly={true}
                                      type="text"
                                      value={values.contactName}
                                      label= {'Quote.Primary'}
                                  />
                              </Grid>

                              <Grid item xs={6} sm={4} md={2}>   
                                  <RegularTextFieldSmall
                                      name={`paymentMethod`}
                                      readOnly={true}
                                      type="text"
                                      value={Text({tid:'Quote.CreditCard'})}
                                      label= {'Quote.PaymentMethod'}
                                  />
                              </Grid>

                              <Grid item xs={6} sm={4} md={2}>   
                                  <RegularTextFieldSmall
                                      name={`paymentAmount`}
                                      readOnly={true}
                                      value={values.paymentAmount}
                                      label= {'Quote.TotalPaymentAmount'}
                                      InputProps={{
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                      }}
                                  />

                              </Grid>

                              <Grid item xs={6} sm={4} md={2}>   
                              </Grid>

                              
                          </Grid>                            

                          {/* credit card info */}
                          <Grid item container spacing={1} style={{padding:'2vh 0'}}>
                              <Grid  item xs={12} sm={12} md={12} style={{ margin: '2vh 0' }}>  
                                      <span className={classes.spanTitle}>
                                          <Text tid={'TravelApplication.CreditCardInfo'}/>
                                      </span>
                              </Grid>

                              <Grid item xs={12} sm={6} md={4}>   
                                  <label className={classes.inputLabel_manualForm}><Text tid={'Quote.CreditCardNumber'}/></label>
                                  <InputMask
                                      name={`creditCardNumber`}
                                      mask= {values.creditCardNumber.substr(0,1) === '3'?"9999 999999 99999":"9999 9999 9999 9999" }
                                      value={values.creditCardNumber}
                                      // style={{ width: '100%' }}
                                      placeholder="MM/YY"
                                      onChange={(e, val)=>{
                                        setFieldValue(`creditCardNumber`,e.target.value )
                                        if (values.creditCardNumber){
                                            let cardType = values.creditCardNumber.substr(0,1)
                                            if (cardType === '4'){
                                            setFieldValue(`creditCardType`,'Visa')
                                            } else if (cardType === '5'){
                                            setFieldValue(`creditCardType`,'MasterCard')
                                            } else if (cardType === '3'){
                                            setFieldValue(`creditCardType`,'AmericanExpress')
                                            } else {
                                            setFieldValue(`creditCardType`,'' )
                                            }
                                        }
                                        console.log(values)
                                      }}
                                      onPaste={(e) => {
                                        const pastedValue = e.clipboardData.getData('Text');
                                        setFieldValue(`creditCardNumber`, pastedValue);
                                        if (pastedValue) {
                                          let cardType = pastedValue.substr(0, 1);
                                          if (cardType === '4') {
                                            setFieldValue(`creditCardType`, 'Visa');
                                          } else if (cardType === '5') {
                                            setFieldValue(`creditCardType`, 'MasterCard');
                                          } else if (cardType === '3') {
                                            setFieldValue(`creditCardType`, 'AmericanExpress');
                                          } else {
                                            setFieldValue(`creditCardType`, '');
                                          }
                                        }
                                      }}
                                      onBlur={handleBlur}                    >
                                      {() => (
                                      <TextField
                                          type="text"
                                          name="creditCardNumber"
                                          variant="outlined"
                                          size="small" 
                                          style={{ width:'100%' }}
                                          InputProps={{
                                          startAdornment: (
                                              <InputAdornment position="start">
                                                  {getCrditCardIcon(values.creditCardNumber.substr(0,1))}
                                              </InputAdornment>
                                          ),
                                          }}
                                      />
                                      )}
                                  </InputMask>
                                  {validMessage(`creditCardNumber`)}
                              </Grid>
                              
                              <Grid item xs={6} sm={4} md={2}>   
                                  <label className={classes.inputLabel_manualForm}><Text tid={'Quote.CardExpiration'}/></label>
                                  <br/>
                                  <InputMask
                                      name={`cardExpired`}
                                      mask="99/99"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values.cardExpired}
                                      placeholder="MM/YY"
                                      >
                                      {() => (
                                          <TextField
                                          type="text"
                                          name="cardExpired"
                                          variant="outlined"
                                          size="small"
                                          />
                                      )}
                                  </InputMask>
                                  {validMessage(`cardExpired`)}
                              </Grid>
                              
                              <Grid item xs={6} sm={4} md={2}>   
                                  <label className={classes.inputLabel_manualForm}><Text tid={'Quote.CardCVV'}/></label>
                                  <br/>
                                  <InputMask
                                      name={`cardcvv`}
                                      mask= {values.creditCardNumber.substr(0,1) === '3'?"9999":"999" }
                                      onChange={handleChange}                      
                                      onBlur={handleBlur}
                                      value={values.cardcvv}
                                      placeholder="MM/YY"
                                      >
                                      {() => (
                                          <TextField
                                          type="text"
                                          name="cardcvv"
                                          variant="outlined"
                                          size="small" 
                                          />
                                      )}
                                  </InputMask>
                                  {validMessage(`cardcvv`)}
                              </Grid>

                              <Grid item xs={12} sm={6} md={4}>   
                                  <RegularTextFieldSmall
                                      name={`cardHolderName`}
                                      type="text"
                                      value={values.cardHolderName}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      label= {'Quote.CardHolderName'}
                                      autoComplete="name"
                                      // style={{ width: '100%' }}
                                  />
                                  {validMessage(`cardHolderName`)}
                              </Grid>
                              
                          </Grid>                            

                      </Grid>

                    </Grid>

                    <Grid item container style={{ justifyContent:'end', marginBottom:'3vh' }}>
                      <Button type='submit' color="dark">
                          <Text tid={"Button.ProceedApplication"}/>
                      </Button>
                    </Grid>

                </Form>
                )}
            </Formik>
          </main>
        </Grid>
      </Grid>
    </>
    )
} else {
    return( 
        <SubmitResult
            formData={formData}
        />   
    )
  
}}

export default Payment
