import 'whatwg-fetch'

/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed JSON from the request
 */
function parseJSON(response) {
  // const contentType = response.headers.get("Content-Type");

  if (response.status === 204 || response.status === 205) {
    return response
  }
  // if (
  //   contentType === "application/json" ||
  //   contentType === "application/vnd.api+json"
  // ) {

  //   return response.json();
  // }

  return response.json()
}

/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param  {object} response   A response from a network request
 *
 * @return {object|undefined} Returns either the response, or throws an error
 */
function checkStatus(response) {
  // Check for status, this should include normal errors (4xx, permissions, etc.)
  // but should exclude 500 errors.
  if (response.status >= 200 && response.status < 500) {
    return response
  }

  const error = new Error(response.statusText)
  error.response = response
  throw error
}

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           The response data
 */
export default function request(url, options) {
  return fetch(url, options)
    .then(checkStatus)
    .then(parseJSON)
}
