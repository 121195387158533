// My Account initial data
export function vendorAccountInit() {
  const data = {
    logo: '',
    organizationName: '',
    email: '',
    phone: '',
    User: [{
      title: '',
      firstName: '',
      lastName: '',
      email: '',
    }],
    streetName: '',
    suiteNum: '',
    city: '',
    provinceName: '',
    postalCode: '',
    countryName: '',
    // Payment: {
    // cardNumber: ''
    // }
  }
    return data
  }