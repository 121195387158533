// Country
export const GET_COUNTRY_REQUESTED = 'GET_COUNTRY_REQUESTED'
export const GET_COUNTRY_SUCCESS = 'GET_COUNTRY_SUCCESS'
export const GET_COUNTRY_FAILED = 'GET_COUNTRY_FAILED'

// Province
export const GET_PROVINCE_REQUESTED = 'GET_PROVINCE_REQUESTED'
export const GET_PROVINCE_SUCCESS = 'GET_PROVINCE_SUCCESS'
export const GET_PROVINCE_FAILED = 'GET_PROVINCE_FAILED'

// Clinic (referral)
export const GET_CLINIC_REQUESTED = 'GET_CLINIC_REQUESTED'
export const GET_CLINIC_SUCCESS = 'GET_CLINIC_SUCCESS'
export const GET_CLINIC_FAILED = 'GET_CLINIC_FAILED'

// Clinic - distance by postalCode (referral)
export const GET_CLINIC_DISTANCE_REQUESTED = 'GET_CLINIC_DISTANCE_REQUESTED'
export const GET_CLINIC_DISTANCE_SUCCESS = 'GET_CLINIC_DISTANCE_SUCCESS'
export const GET_CLINIC_DISTANCE_FAILED = 'GET_CLINIC_DISTANCE_FAILED'

// Get all Insurance Plan
export const GET_INSURANCE_PLAN_REQUESTED = 'GET_INSURANCE_PLAN_REQUESTED'
export const GET_INSURANCE_PLAN_SUCCESS = 'GET_INSURANCE_PLAN_SUCCESS'
export const GET_INSURANCE_PLAN_FAILED = 'GET_INSURANCE_PLAN_FAILED'

// Put Insurance Plans
export const PUT_INSURANCE_PLAN_REQUESTED = 'PUT_INSURANCE_PLAN_REQUESTED'
export const PUT_INSURANCE_PLAN_SUCCESS = 'PUT_INSURANCE_PLAN_SUCCESS'
export const PUT_INSURANCE_PLAN_FAILED = 'PUT_INSURANCE_PLAN_FAILED'

// Insurance all
export const GET_INSURANCE_REQUESTED = 'GET_INSURANCE_REQUESTED'
export const GET_INSURANCE_SUCCESS = 'GET_INSURANCE_SUCCESS'
export const GET_INSURANCE_FAILED = 'GET_INSURANCE_FAILED'

// Insurance by Eligibility
export const GET_INSURANCE_ELIG_REQUESTED = 'GET_INSURANCE_ELIG_REQUESTED'
export const GET_INSURANCE_ELIG_SUCCESS = 'GET_INSURANCE_ELIG_SUCCESS'
export const GET_INSURANCE_ELIG_FAILED = 'GET_INSURANCE_ELIG_FAILED'

// medical questionnaires
export const GET_MED_QUESTION_REQUESTED = 'GET_MED_QUESTION_REQUESTED'
export const GET_MED_QUESTION_SUCCESS = 'GET_MED_QUESTION_SUCCESS'
export const GET_MED_QUESTION_FAILED = 'GET_MED_QUESTION_FAILED'

// Quotation all
export const GET_QUOTATION_REQUESTED = 'GET_QUOTATION_REQUESTED'
export const GET_QUOTATION_SUCCESS = 'GET_QUOTATION_SUCCESS'
export const GET_QUOTATION_FAILED = 'GET_QUOTATION_FAILED'

// Quotation List
export const GET_QUOTATION_LIST_REQUESTED = 'GET_QUOTATION_LIST_REQUESTED'
export const GET_QUOTATION_LIST_SUCCESS = 'GET_QUOTATION_LIST_SUCCESS'
export const GET_QUOTATION_LIST_FAILED = 'GET_QUOTATION_LIST_FAILED'

// Quotation by user email
export const GET_QUOTATION_EMAIL_REQUESTED = 'GET_QUOTATION_EMAIL_REQUESTED'
export const GET_QUOTATION_EMAIL_SUCCESS = 'GET_QUOTATION_EMAIL_SUCCESS'
export const GET_QUOTATION_EMAIL_FAILED = 'GET_QUOTATION_EMAIL_FAILED'

// Insurance plan document all
export const GET_PLAN_DOCUMENT_REQUESTED = 'GET_PLAN_DOCUMENT_REQUESTED'
export const GET_PLAN_DOCUMENT_SUCCESS = 'GET_PLAN_DOCUMENT_SUCCESS'
export const GET_PLAN_DOCUMENT_FAILED = 'GET_PLAN_DOCUMENT_FAILED'

// Account all
export const GET_ACCOUNT_REQUESTED = 'GET_ACCOUNT_REQUESTED'
export const GET_ACCOUNT_SUCCESS = 'GET_ACCOUNT_SUCCESS'
export const GET_ACCOUNT_FAILED = 'GET_ACCOUNT_FAILED'

// Account by user email
export const GET_ACCOUNT_EMAIL_REQUESTED = 'GET_ACCOUNT_EMAIL_REQUESTED'
export const GET_ACCOUNT_EMAIL_SUCCESS = 'GET_ACCOUNT_EMAIL_SUCCESS'
export const GET_ACCOUNT_EMAIL_FAILED = 'GET_ACCOUNT_EMAIL_FAILED'

// Account by userId
export const GET_ACCOUNT_ID_REQUESTED = 'GET_ACCOUNT_ID_REQUESTED'
export const GET_ACCOUNT_ID_SUCCESS = 'GET_ACCOUNT_ID_SUCCESS'
export const GET_ACCOUNT_ID_FAILED = 'GET_ACCOUNT_ID_FAILED'

// Accouts by vendor
export const GET_USERS_BY_VENDOR_REQUESTED = 'GET_USERS_BY_VENDOR_REQUESTED'
export const GET_USERS_BY_VENDOR_SUCCESS = 'GET_USERS_BY_VENDOR_SUCCESS'
export const GET_USERS_BY_VENDOR_FAILED = 'GET_USERS_BY_VENDOR_FAILED'


// Signup
export const SIGNUP_REQUESTED = 'SIGNUP_REQUESTED'
export const SIGNUP_REQUEST_SUCCESS = 'SIGNUP_REQUEST_SUCCESS'
export const SIGNUP_REQUEST_FAILED = 'SIGNUP_REQUEST_FAILED'
export const SIGNUP_STATE_CLEAR = 'SIGNUP_STATE_CLEAR'

// Login
export const SIGNIN_REQUESTED = 'SIGNIN_REQUESTED'
export const SIGNIN_REQUEST_SUCCESS = 'SIGNIN_REQUEST_SUCCESS'
export const SIGNIN_REQUEST_FAILED = 'SIGNIN_REQUEST_FAILED'
export const SIGNIN_STATE_CLEAR = 'SIGNIN_STATE_CLEAR'
export const USER_LOGOUT = 'USER_LOGOUT'

// Reset password
// verify user before reset password
export const VERIFY_USER_REQUESTED = 'VERIFY_USER_REQUESTED'
export const VERIFY_USER_REQUEST_SUCCESS = 'VERIFY_USER_REQUEST_SUCCESS'
export const VERIFY_USER_REQUEST_FAILED = 'VERIFY_USER_REQUEST_FAILED'
// change password
export const CHANGE_PASSWORD_REQUESTED = 'CHANGE_PASSWORD_REQUESTED'
export const CHANGE_PASSWORD_REQUEST_SUCCESS = 'CHANGE_PASSWORD_REQUEST_SUCCESS'
export const CHANGE_PASSWORD_REQUEST_FAILED = 'CHANGE_PASSWORD_REQUEST_FAILED'

//Manual Form Insurance by Product
export const GET_INSURANCE_PRODUCT_REQUESTED = 'GET_INSURANCE_PRODUCT_REQUESTED'
export const GET_INSURANCE_PRODUCT_SUCCESS = 'GET_INSURANCE_PRODUCT_SUCCESS'
export const GET_INSURANCE_PRODUCT_FAILED = 'GET_INSURANCE_PRODUCT_FAILED'

//Vendor  (STUDENT, VISITOR, CANADIAN) Insurance Student Group Plan
export const GET_INSURANCE_TYPE_REQUESTED =
  'GET_INSURANCE_TYPE_REQUESTED'
export const GET_INSURANCE_TYPE_SUCCESS =
  'GET_INSURANCE_TYPE_SUCCESS'
export const GET_INSURANCE_TYPE_FAILED =
  'GET_INSURANCE_TYPE_FAILED'

// Vendor Insurance Student Group Plan
export const GET_STUDENT_GROUP_INSURANCE_TYPE_REQUESTED =
  'GET_STUDENT_GROUP_INSURANCE_TYPE_REQUESTED'
export const GET_STUDENT_GROUP_INSURANCE_TYPE_SUCCESS =
  'GET_STUDENT_GROUP_INSURANCE_TYPE_SUCCESS'
export const GET_STUDENT_GROUP_INSURANCE_TYPE_FAILED =
  'GET_STUDENT_GROUP_INSURANCE_TYPE_FAILED'


// Vendor Account
// Get Vendor Account all
export const GET_VENDOR_ACCOUNT_REQUESTED = 'GET_VENDOR_ACCOUNT_REQUESTED'
export const GET_VENDOR_ACCOUNT_SUCCESS = 'GET_VENDOR_ACCOUNT_SUCCESS'
export const GET_VENDOR_ACCOUNT_FAILED = 'GET_VENDOR_ACCOUNT_FAILED'

// Get Vendor Account all
export const GET_VENDOR_ACCOUNT_BY_ACCESS_CODE_REQUESTED = 'GET_VENDOR_ACCOUNT_BY_ACCESS_CODE_REQUESTED'
export const GET_VENDOR_ACCOUNT_BY_ACCESS_CODE_SUCCESS = 'GET_VENDOR_ACCOUNT_BY_ACCESS_CODE_SUCCESS'
export const GET_VENDOR_ACCOUNT_BY_ACCESS_CODE_FAILED = 'GET_VENDOR_ACCOUNT_BY_ACCESS_CODE_FAILED'

// Post Vendor Account 
export const POST_VENDOR_ACCOUNT_REQUESTED = 'POST_VENDOR_ACCOUNT_REQUESTED'
export const POST_VENDOR_ACCOUNT_SUCCESS = 'POST_VENDOR_ACCOUNT_SUCCESS'
export const POST_VENDOR_ACCOUNT_FAILED = 'POST_VENDOR_ACCOUNT_FAILED'

// Put Vendor Account
export const PUT_VENDOR_ACCOUNT_REQUESTED = 'PUT_VENDOR_ACCOUNT_REQUESTED'
export const PUT_VENDOR_ACCOUNT_SUCCESS = 'PUT_VENDOR_ACCOUNT_SUCCESS'
export const PUT_VENDOR_ACCOUNT_FAILED = 'PUT_VENDOR_ACCOUNT_FAILED'

// Post Vendor User Account 
export const POST_VENDOR_USER_REQUESTED = 'POST_VENDOR_USER_REQUESTED'
export const POST_VENDOR_USER_SUCCESS = 'POST_VENDOR_USER_SUCCESS'
export const POST_VENDOR_USER_FAILED = 'POST_VENDOR_USER_FAILED'

// Put Vendor User Account 
export const PUT_VENDOR_USER_REQUESTED = 'PUT_VENDOR_USER_REQUESTED'
export const PUT_VENDOR_USER_SUCCESS = 'PUT_VENDOR_USER_SUCCESS'
export const PUT_VENDOR_USER_FAILED = 'PUT_VENDOR_USER_FAILED'


// Insured person
// Get insured person by vendor
export const GET_INSURED_BY_VENDOR_REQUESTED = 'GET_INSURED_BY_VENDOR_REQUESTED'
export const GET_INSURED_BY_VENDOR_SUCCESS = 'GET_INSURED_BY_VENDOR_SUCCESS'
export const GET_INSURED_BY_VENDOR_FAILED = 'GET_INSURED_BY_VENDOR_FAILED'


// Travel Application 
// Get Travel Application 
export const GET_APPLICAION_REQUESTED = 'GET_APPLICAION_REQUESTED'
export const GET_APPLICAION_SUCCESS = 'GET_APPLICAION_SUCCESS'
export const GET_APPLICAION_FAILED = 'GET_APPLICAION_FAILED'

// Get Travel Applicant's medical questionnaires 
export const GET_APPLICANT_MED_ANSWER_REQUESTED = 'GET_APPLICANT_MED_ANSWER_REQUESTED'
export const GET_APPLICANT_MED_ANSWER_SUCCESS = 'GET_APPLICANT_MED_ANSWER_SUCCESS'
export const GET_APPLICANT_MED_ANSWER_FAILED = 'GET_APPLICANT_MED_ANSWER_FAILED'

// Get Travel Application By Client
export const GET_APPLICAION_BY_CLIENT_REQUESTED = 'GET_APPLICAION_BY_CLIENT_REQUESTED'
export const GET_APPLICAION_BY_CLIENT_SUCCESS = 'GET_APPLICAION_BY_CLIENT_SUCCESS'
export const GET_APPLICAION_BY_CLIENT_FAILED = 'GET_APPLICAION_BY_CLIENT_FAILED'

// Get Renewable Application
export const GET_RENEWABLE_APPLICAION_REQUESTED = 'GET_RENEWABLE_APPLICAION_REQUESTED'
export const GET_RENEWABLE_APPLICAION_SUCCESS = 'GET_RENEWABLE_APPLICAION_SUCCESS'
export const GET_RENEWABLE_APPLICAION_FAILED = 'GET_RENEWABLE_APPLICAION_FAILED'

// Post Travel Application 
export const POST_APPLICAION_REQUESTED = 'POST_APPLICAION_REQUESTED'
export const POST_APPLICAION_SUCCESS = 'POST_APPLICAION_SUCCESS'
export const POST_APPLICAION_FAILED = 'POST_APPLICAION_FAILED'

// Put (update payment) Travel Application 
export const PUT_APPLICAION_PAYMENT_REQUESTED = 'PUT_APPLICAION_PAYMENT_REQUESTED'
export const PUT_APPLICAION_PAYMENT_SUCCESS = 'PUT_APPLICAION_PAYMENT_SUCCESS'
export const PUT_APPLICAION_PAYMENT_FAILED = 'PUT_APPLICAION_PAYMENT_FAILED'

// Put (update status) Travel Application 
export const PUT_APPLICAION_STATUS_REQUESTED = 'PUT_APPLICAION_STATUS_REQUESTED'
export const PUT_APPLICAION_STATUS_SUCCESS = 'PUT_APPLICAION_STATUS_SUCCESS'
export const PUT_APPLICAION_STATUS_FAILED = 'PUT_APPLICAION_STATUS_FAILED'

// Put (update) Travel Application 
export const PUT_APPLICAION_REQUESTED = 'PUT_APPLICAION_REQUESTED'
export const PUT_APPLICAION_SUCCESS = 'PUT_APPLICAION_SUCCESS'
export const PUT_APPLICAION_FAILED = 'PUT_APPLICAION_FAILED'

// Send email after Gettting Renewable Application
export const SEND_EMAIL_RENEWABLE_APPLICAION_REQUESTED = 'SEND_EMAIL_RENEWABLE_APPLICAION_REQUESTED'
export const SEND_EMAIL_RENEWABLE_APPLICAION_SUCCESS = 'SEND_EMAIL_RENEWABLE_APPLICAION_SUCCESS'
export const SEND_EMAIL_RENEWABLE_APPLICAION_FAILED = 'SEND_EMAIL_RENEWABLE_APPLICAION_FAILED'

// Get (merge ZCRM Sales to ZApplications )
export const GET_MERGE_ZAPPLICAION_REQUESTED = 'GET_MERGE_ZAPPLICAION_REQUESTED'
export const GET_MERGE_ZAPPLICAION_SUCCESS = 'GET_MERGE_ZAPPLICAION_SUCCESS'
export const GET_MERGE_ZAPPLICAION_FAILED = 'GET_MERGE_ZAPPLICAION_FAILED'

// Send email to clinet
export const SEND_EMAIL_CLIENT_REQUESTED = 'SEND_EMAIL_CLIENT_REQUESTED'
export const SEND_EMAIL_CLIENT_SUCCESS = 'SEND_EMAIL_CLIENT_SUCCESS'
export const SEND_EMAIL_CLIENT_FAILED = 'SEND_EMAIL_CLIENT_FAILED'

// Put (sell Tugo Policies & update policy number) Travel Application 
export const PUT_SELL_TUGO_POLICY_REQUESTED = 'PUT_SELL_TUGO_POLICY_REQUESTED'
export const PUT_SELL_TUGO_POLICY_SUCCESS = 'PUT_SELL_TUGO_POLICY_SUCCESS'
export const PUT_SELL_TUGO_POLICY_FAILED = 'PUT_SELL_TUGO_POLICY_FAILED'

// Put quote Allianz
export const PUT_QUOTE_ALLIANZ_REQUESTED = 'PUT_QUOTE_ALLIANZ_REQUESTED'
export const PUT_QUOTE_ALLIANZ_SUCCESS = 'PUT_QUOTE_ALLIANZ_SUCCESS'
export const PUT_QUOTE_ALLIANZ_FAILED = 'PUT_QUOTE_ALLIANZ_FAILED'

// Put quote Allianz Canadian Medical Question 
export const PUT_QUOTE_ALLIANZ_MED_CAN_REQUESTED = 'PUT_QUOTE_ALLIANZ_MED_CAN_REQUESTED'
export const PUT_QUOTE_ALLIANZ_MED_CAN_SUCCESS = 'PUT_QUOTE_ALLIANZ_MED_CAN_SUCCESS'
export const PUT_QUOTE_ALLIANZ_MED_CAN_FAILED = 'PUT_QUOTE_ALLIANZ_MED_CAN_FAILED'

// Put (sell Allianz Policies & update policy number) Travel Application 
export const PUT_SELL_ALLIANZ_POLICY_REQUESTED = 'PUT_SELL_ALLIANZ_POLICY_REQUESTED'
export const PUT_SELL_ALLIANZ_POLICY_SUCCESS = 'PUT_SELL_ALLIANZ_POLICY_SUCCESS'
export const PUT_SELL_ALLIANZ_POLICY_FAILED = 'PUT_SELL_ALLIANZ_POLICY_FAILED'

// Put (sell Carewell) Travel Application
export const PUT_SELL_CAREWELL_REQUESTED = 'PUT_SELL_CAREWELL_REQUESTED'
export const PUT_SELL_CAREWELL_SUCCESS = 'PUT_SELL_CAREWELL_SUCCESS'
export const PUT_SELL_CAREWELL_FAILED = 'PUT_SELL_CAREWELL_FAILED'

// Life Quote 
// Get
export const GET_LIFE_QUOTE_REQUESTED = 'GET_LIFE_QUOTE_REQUESTED'
export const GET_LIFE_QUOTE_SUCCESS = 'GET_LIFE_QUOTE_SUCCESS'
export const GET_LIFE_QUOTE_FAILED = 'GET_LIFE_QUOTE_FAILED'
// Post
export const POST_LIFE_QUOTE_REQUESTED = 'POST_LIFE_QUOTE_REQUESTED'
export const POST_LIFE_QUOTE_SUCCESS = 'POST_LIFE_QUOTE_SUCCESS'
export const POST_LIFE_QUOTE_FAILED = 'POST_LIFE_QUOTE_FAILED'

// Get Life Quote By Client
export const GET_LIFE_QUOTE_BY_CLIENT_REQUESTED = 'GET_LIFE_QUOTE_BY_CLIENT_REQUESTED'
export const GET_LIFE_QUOTE_BY_CLIENT_SUCCESS = 'GET_LIFE_QUOTE_BY_CLIENT_SUCCESS'
export const GET_LIFE_QUOTE_BY_CLIENT_FAILED = 'GET_LIFE_QUOTE_BY_CLIENT_FAILED'

// Health Quote 
// Get
export const GET_HEALTH_QUOTE_REQUESTED = 'GET_HEALTH_QUOTE_REQUESTED'
export const GET_HEALTH_QUOTE_SUCCESS = 'GET_HEALTH_QUOTE_SUCCESS'
export const GET_HEALTH_QUOTE_FAILED = 'GET_HEALTH_QUOTE_FAILED'
// Post
export const POST_HEALTH_QUOTE_REQUESTED = 'POST_HEALTH_QUOTE_REQUESTED'
export const POST_HEALTH_QUOTE_SUCCESS = 'POST_HEALTH_QUOTE_SUCCESS'
export const POST_HEALTH_QUOTE_FAILED = 'POST_HEALTH_QUOTE_FAILED'

// Group Quote 
// Get
export const GET_GROUP_QUOTE_REQUESTED = 'GET_GROUP_QUOTE_REQUESTED'
export const GET_GROUP_QUOTE_SUCCESS = 'GET_GROUP_QUOTE_SUCCESS'
export const GET_GROUP_QUOTE_FAILED = 'GET_GROUP_QUOTE_FAILED'
// Post
export const POST_GROUP_QUOTE_REQUESTED = 'POST_GROUP_QUOTE_REQUESTED'
export const POST_GROUP_QUOTE_SUCCESS = 'POST_GROUP_QUOTE_SUCCESS'
export const POST_GROUP_QUOTE_FAILED = 'POST_GROUP_QUOTE_FAILED'

// Refund Request 
// Post
export const POST_REFUND_REQUEST_REQUESTED = 'POST_REFUND_REQUEST_REQUESTED'
export const POST_REFUND_REQUEST_SUCCESS = 'POST_REFUND_REQUEST_SUCCESS'
export const POST_REFUND_REQUEST_FAILED = 'POST_REFUND_REQUEST_FAILED'
// Get
export const GET_REFUND_REQUEST_REQUESTED = 'GET_REFUND_REQUEST_REQUESTED'
export const GET_REFUND_REQUEST_SUCCESS = 'GET_REFUND_REQUEST_SUCCESS'
export const GET_REFUND_REQUEST_FAILED = 'GET_REFUND_REQUEST_FAILED'
// Send requested refund email to provider
export const SEND_EMAIL_PROVIDER_REQUESTED = 'SEND_EMAIL_PROVIDER_REQUESTED'
export const SEND_EMAIL_PROVIDER_SUCCESS = 'SEND_EMAIL_PROVIDER_SUCCESS'
export const SEND_EMAIL_PROVIDER_FAILED = 'SEND_EMAIL_PROVIDER_FAILED'
// Put
export const PUT_REFUND_STATUS_REQUESTED = 'PUT_REFUND_STATUS_REQUESTED'
export const PUT_REFUND_STATUS_SUCCESS = 'PUT_REFUND_STATUS_SUCCESS'
export const PUT_REFUND_STATUS_FAILED = 'PUT_REFUND_STATUS_FAILED'

// Claim Request 
// Post
export const POST_CLAIM_REQUEST_REQUESTED = 'POST_CLAIM_REQUEST_REQUESTED'
export const POST_CLAIM_REQUEST_SUCCESS = 'POST_CLAIM_REQUEST_SUCCESS'
export const POST_CLAIM_REQUEST_FAILED = 'POST_CLAIM_REQUEST_FAILED'
// Get
export const GET_CLAIM_REQUEST_REQUESTED = 'GET_CLAIM_REQUEST_REQUESTED'
export const GET_CLAIM_REQUEST_SUCCESS = 'GET_CLAIM_REQUEST_SUCCESS'
export const GET_CLAIM_REQUEST_FAILED = 'GET_CLAIM_REQUEST_FAILED'
// Put
export const PUT_CLAIM_STATUS_REQUESTED = 'PUT_CLAIM_STATUS_REQUESTED'
export const PUT_CLAIM_STATUS_SUCCESS = 'PUT_CLAIM_STATUS_SUCCESS'
export const PUT_CLAIM_STATUS_FAILED = 'PUT_CLAIM_STATUS_FAILED'

// Get file from aws s3 
export const GET_FILE_FROMS3_REQUESTED = 'GET_FILE_FROMS3_REQUESTED'
export const GET_FILE_FROMS3_SUCCESS = 'GET_FILE_FROMS3_SUCCESS'
export const GET_FILE_FROMS3_FAILED = 'GET_FILE_FROMS3_FAILED'
export const GET_MULTIFILES_FROMS3_REQUESTED = 'GET_MULTIFILES_FROMS3_REQUESTED'
export const GET_MULTIFILES_FROMS3_SUCCESS = 'GET_MULTIFILES_FROMS3_SUCCESS'
export const GET_MULTIFILES_FROMS3_FAILED = 'GET_MULTIFILES_FROMS3_FAILED'

export const DOWNLOAD_FILE_REQUESTED = 'DOWNLOAD_FILE_REQUESTED';


// Contact Us 
export const POST_CONTACT_US_REQUESTED = 'POST_CONTACT_US_REQUESTED'
export const POST_CONTACT_US_SUCCESS = 'POST_CONTACT_US_SUCCESS'
export const POST_CONTACT_US_FAILED = 'POST_CONTACT_US_FAILED'

// Claim-Case
export const GET_CLAIM_CASE_REQUESTED = 'GET_CLAIM_CASE_REQUESTED'
export const GET_CLAIM_CASE_SUCCESS = 'GET_CLAIM_CASE_SUCCESS'
export const GET_CLAIM_CASE_FAILED = 'GET_CLAIM_CASE_FAILED'

// Vendor Statement
// Get Vendor Statement 
export const GET_VENDORSTATEMENT_REQUESTED = 'GET_VENDORSTATEMENT_REQUESTED'
export const GET_VENDORSTATEMENT_SUCCESS = 'GET_VENDORSTATEMENT_SUCCESS'
export const GET_VENDORSTATEMENT_FAILED = 'GET_VENDORSTATEMENT_FAILED'

// Get Vendor Statement By Vendor
export const GET_VENDORSTATEMENT_BY_VENDOR_REQUESTED = 'GET_VENDORSTATEMENT_BY_VENDOR_REQUESTED'
export const GET_VENDORSTATEMENT_BY_VENDOR_SUCCESS = 'GET_VENDORSTATEMENT_BY_VENDOR_SUCCESS'
export const GET_VENDORSTATEMENT_BY_VENDOR_FAILED = 'GET_VENDORSTATEMENT_BY_VENDOR_FAILED'

//Credit Card
// Get Credit Card Information
export const GET_CREDIT_CARD_REQUESTED = 'GET_CREDIT_CARD_REQUESTED'
export const GET_CREDIT_CARD_SUCCESS = 'GET_CREDIT_CARD_SUCCESS'
export const GET_CREDIT_CARD_FAILED = 'GET_CREDIT_CARD_FAILED'
// Post Credit Card Information
export const POST_CREDIT_CARD_REQUESTED = 'POST_CREDIT_CARD_REQUESTED'
export const POST_CREDIT_CARD_SUCCESS = 'POST_CREDIT_CARD_SUCCESS'
export const POST_CREDIT_CARD_FAILED = 'POST_CREDIT_CARD_FAILED'
// Put (update) Credit Card Information
export const PUT_CREDIT_CARD_STATUS_REQUESTED = 'PUT_CREDIT_CARD_STATUS_REQUESTED'
export const PUT_CREDIT_CARD_STATUS_SUCCESS = 'PUT_CREDIT_CARD_STATUS_SUCCESS'
export const PUT_CREDIT_CARD_STATUS_FAILED = 'PUT_CREDIT_CARD_STATUS_FAILED'

//GROUP ENROLMENT FROM
// Get GROUP_ENROLMENT
export const GET_GROUP_ENROLMENT_REQUESTED = 'GET_GROUP_ENROLMENT_REQUESTED'
export const GET_GROUP_ENROLMENT_SUCCESS = 'GET_GROUP_ENROLMENT_SUCCESS'
export const GET_GROUP_ENROLMENT_FAILED = 'GET_GROUP_ENROLMENT_FAILED'
// Post GROUP_ENROLMENT
export const POST_GROUP_ENROLMENT_REQUESTED = 'POST_GROUP_ENROLMENT_REQUESTED'
export const POST_GROUP_ENROLMENT_SUCCESS = 'POST_GROUP_ENROLMENT_SUCCESS'
export const POST_GROUP_ENROLMENT_FAILED = 'POST_GROUP_ENROLMENT_FAILED'

//Travel Quote Over 60 Canadian 
// Get
export const GET_TRAVEL_QUOTE_OVER_60_REQUESTED = 'GET_TRAVEL_QUOTE_OVER_60_REQUESTED'
export const GET_TRAVEL_QUOTE_OVER_60_SUCCESS = 'GET_TRAVEL_QUOTE_OVER_60_SUCCESS'
export const GET_TRAVEL_QUOTE_OVER_60_FAILED = 'GET_TRAVEL_QUOTE_OVER_60_FAILED'
// Post
export const POST_TRAVEL_QUOTE_OVER_60_REQUESTED = 'POST_TRAVEL_QUOTE_OVER_60_REQUESTED'
export const POST_TRAVEL_QUOTE_OVER_60_SUCCESS = 'POST_TRAVEL_QUOTE_OVER_60_SUCCESS'
export const POST_TRAVEL_QUOTE_OVER_60_FAILED = 'POST_TRAVEL_QUOTE_OVER_60_FAILED'
// Put
export const PUT_TRAVEL_QUOTE_OVER_60_REQUESTED = 'PUT_TRAVEL_QUOTE_OVER_60_REQUESTED'
export const PUT_TRAVEL_QUOTE_OVER_60_SUCCESS = 'PUT_TRAVEL_QUOTE_OVER_60_SUCCESS'
export const PUT_TRAVEL_QUOTE_OVER_60_FAILED = 'PUT_TRAVEL_QUOTE_OVER_60_FAILED'