import React from 'react';

export default function ClientDashboard() { 

  return (
    <div>
      Client - Dashboard
    </div>
  )
}
